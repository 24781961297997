import React from 'react'

import MainLayout from "../layouts/mainlayout/MainLayout"
import PageHeader from '../components/pageheader/PageHeader'

const donate = () => {
	return (
		<MainLayout title="Donate">
			<PageHeader title="Donate" />
			Donate
		</MainLayout>
	)
}

export default donate